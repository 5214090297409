import React from 'react';
import isEqual from "react-fast-compare";
import FirebaseAuth from './FirebaseAuth';


const initialState = {
  user: false,
  userAccount: false,
  firebaseAuth: null,
};

const firebaseAuth = new FirebaseAuth();
initialState.firebaseAuth = firebaseAuth;

function getNewUserAccountState(state, newUserAccount) {
  if(!state.userAccount) {
    return {
      ...state,
      userAccount: newUserAccount,
      lastSeenExerciseCreated: newUserAccount.latestExerciseCreated
    };
  }
  return isEqual(state.userAccount, newUserAccount) ? state : { ...state, userAccount: newUserAccount};
}

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.user,
        userAccount: null,
      }
    case "SET_USER_ACCOUNT":
      return getNewUserAccountState(state, action.userAccount);

    default:
      return state
  }
}

export const UserContext = React.createContext({
  userState: initialState,
  userDispatch: () => null
});

export const UserProvider = ({ children }) => {

  const [userState, userDispatch] = React.useReducer(reducer, initialState)

  React.useEffect(() => {
    function setLoggedInUser(user) {
      userDispatch({type: "SET_USER", user: user});
    }
    firebaseAuth.onAuthStateChangedCallback(setLoggedInUser);
  }, []);

  return (
    <UserContext.Provider value={[ userState, userDispatch ]}>
      { children }
    </UserContext.Provider>
  )
};
