import * as firebaseui from 'firebaseui';
import firebase from 'firebase/compat/app';
//import * as firebase from 'firebase';
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDeIaVbp2tu996qjV-IfRY2b4vCVe3F8HA",
  authDomain: "mytracks-run.firebaseapp.com",
  projectId: "mytracks-run",
  appId: "1:848539841755:web:132d4ac8b269ec52cc88fe"
};

const authUIConfig = {
  callbacks: {
    signInSuccessWithAuthResult: function(authResult, redirectUrl) {
      return false; // no redirect
    },
  },
  signInFlow: 'popup',
  signInSuccessUrl: process.env.REACT_APP_APPLICATION_ROOT_URL,
  signInOptions: [
    /* TODO: implement other authentication providers
     {
       provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
       signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
       forceSameDevice: false,
     },
     */
     firebase.auth.GoogleAuthProvider.PROVIDER_ID,
     /* TODO: implement other authentication providers
     {
       provider: 'microsoft.com',
       loginHintKey: 'login_hint',
       customParameters: {
         prompt: "consent",
         tenant: "5939e38d-9f31-44d6-b19c-0ea7db4fd887",
       },
     }
     */
   ],
   //credentialHelper: firebaseui.auth.CredentialHelper.NONE,
   credentialHelper: firebaseui.auth.CredentialHelper.ACCOUNT_CHOOSER_COM,
 };

export default class FirebaseAuth {

  constructor() {
    firebase.initializeApp(firebaseConfig);
    this.auth = firebase.auth();
    if(process.env.REACT_APP_FIREBASE_EMULATOR === 'true') {
      console.log("== FIREBASE EMULATOR!");
      this.auth.useEmulator('http://localhost:9099/');
    } else {
      console.log("== FIREBASE *NO* EMULATOR!");
    }
    this.authUI = new firebaseui.auth.AuthUI(this.auth);
    this.signOutInProgress = false;
  }

  signInWithCustomToken(token) {
    this.auth.signInWithCustomToken(token).catch(function(error) {
      console.log("CUSTOM TOKEN LOGIN ERROR", error.code, error.message);
    });
  }

  isSignOutInProgress() {
    return this.signOutInProgress;
  }

  setSignOutInProgress(signOutInProgress) {
    this.signOutInProgress = signOutInProgress;
  }

  onAuthStateChangedCallback(func) {
    this.auth.onAuthStateChanged(user => {
      if(user !== null) {
        this.signOutInProgress = false;
      }
      func(user);
    });
  }

  isPendingRedirect() {
    return this.authUI.isPendingRedirect();
  }

  start(elementId) {
    this.authUI.start(elementId, authUIConfig);
  }

  signOut() {
    this.signOutInProgress = true;
    this.auth.signOut();
  }

  auth() {
    return this.auth;
  }
}
