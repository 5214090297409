import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { UserProvider } from './components/userContext';

import './App.css';

const theme = createTheme();

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Pages
const Login = React.lazy(() => import('./pages/login/Login'));
const Layout = React.lazy(() => import('./layout/Layout'));

function App() {

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <UserProvider>
          <HashRouter>
            <React.Suspense fallback={loading}>
              <Routes>
                <Route exact path="/login" element={<Login />} />
                <Route path="/*" element={<Layout />} />
              </Routes>
            </React.Suspense>
          </HashRouter>
        </UserProvider>
      </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
